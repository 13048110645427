import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMediaQuery } from '@react-hooks-library/core';
import React, { useCallback, useState, useContext, useMemo, useEffect, } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useFormContext } from 'react-hook-form';
import { MobileModalForm } from '../core';
import { PlusIcon, MinusIcon, PositionArrowIcon } from '../core/icons';
import OfficeCard from '../core/visualComponents/OfficeCard';
import { officeActions } from '../map/store/officeStore';
// Примеры работы с картой взял здесь:
// https://codesandbox.io/p/sandbox/elegant-burnell-wxn9cy?file=%2Fsrc%2Fhelpers.ts%3A33%2C13
// там ещё есть тема с полигонами - можно на фронте отрисовать полигоны в админке, чтоб бизнес видел зоны работы
import { 
// DEFAULT_ZOOM,
DELTAS_FOR_ZOOM, MOBILE_DELTAS_FOR_ZOOM, 
// MAP_OPTIONS,
MAX_ZOOM, MIN_ZOOM, } from './consts';
import Marker from './Marker';
import isEqual from 'lodash/isEqual';
import './styles.css';
import { YMap, YMapComponentsProvider, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapListener, 
// YMapFeature,
// YMapCollection,
// YMapControls,
// YMapGeolocationControl,
// YMapZoomControl,
YMapHint, 
// YMapDefaultMarker,
// YMapContainer,
// YMapControlButton,
YMapHintContext,
// YMapMarker,
// YMapClusterer,
 } from 'ymap3-components';
// import * as YMaps from '@yandex/ymaps3-types';
// import { LngLat } from '@yandex/ymaps3-types';
function MyHint() {
    const hint = useContext(YMapHintContext);
    return (_jsxs("div", { className: 'hint', children: [_jsx("div", { className: 'hint-header', children: hint === null || hint === void 0 ? void 0 : hint.hint }), _jsx("div", { className: 'hint-hint', children: hint === null || hint === void 0 ? void 0 : hint.hint }), _jsx("div", { className: 'hint-desc', children: hint === null || hint === void 0 ? void 0 : hint.hint })] }));
}
/* const MapLocation = ({ location }: any) => {
  return (
    <div className='map-info'>
      <div className='map-info-header'>Center</div>
      <div className='map-info-text'>lat: { location.center[0].toFixed(2) }</div>
      <div className='map-info-text'>long: { location.center[1].toFixed(2) }</div>
      <div className='map-info-header'>Zoom</div>
      <div className='map-info-text'>zoom: { location.zoom.toFixed(2) }</div>
    </div>
  );
}; */
const MapV2 = ({ officeStore }) => {
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { partnerOfficeId, partnerOfficeIdCentered } = values; // partnerOfficeIdCentered - для мобильной версии для кнопку "Показать на карте нужно"
    // const [tempZoom, setTempZoom] = useState<number>(DEFAULT_ZOOM);
    const [selected, setSelected] = useState(partnerOfficeId);
    const params = new URLSearchParams(window.location.search);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const defaultLocation = { center: [37.610225, 55.751344], zoom: 11 };
    const [location, setLocation] = useState(defaultLocation);
    const [viewLocation, setViewLocation] = useState(defaultLocation);
    const deltasForZoom = useMemo(() => (isMobile ? MOBILE_DELTAS_FOR_ZOOM : DELTAS_FOR_ZOOM), [isMobile]);
    // const [deltas, setDeltas] = useState(deltasForZoom);
    const onUpdate = React.useCallback(({ location, mapInAction }) => {
        // console.log('location=,location,mapInAction', location, mapInAction);
        if (!mapInAction && !isEqual(location, {
            center: location.center,
            zoom: location.zoom,
        })) {
            setViewLocation({
                center: location.center,
                zoom: location.zoom,
            });
        }
    }, []);
    const getHint = useCallback((object) => { var _a; return (_a = object === null || object === void 0 ? void 0 : object.properties) === null || _a === void 0 ? void 0 : _a.hint; }, []);
    const zoomIn = () => {
        const newLocation = Object.assign(Object.assign({}, viewLocation), { zoom: viewLocation.zoom + 1 });
        setLocation(newLocation);
    };
    const zoomOut = () => {
        const newLocation = Object.assign(Object.assign({}, viewLocation), { zoom: viewLocation.zoom - 1 });
        setLocation(newLocation);
    };
    const offices = useMemo(() => { var _a; return (_a = toJS(officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices)) !== null && _a !== void 0 ? _a : []; }, [officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices]);
    const visibleOffices = useMemo(() => offices, [offices]);
    const handleCenter = useCallback((newCenter) => {
        setLocation({
            center: [newCenter.lng, newCenter.lat],
            zoom: location.zoom,
        });
        /* setCenter(prevCenter => {
          // карта не реагирует на более мелкие изменения центра
          if (prevCenter.lat === newCenter.lat && prevCenter.lng === newCenter.lng) {
            return {
              lat: newCenter.lat + 0.00001,
              lng: newCenter.lng + 0.00001,
            };
          }
          return newCenter;
        });*/
    }, [location.zoom]);
    // далее lifeHack - по viewLocation берём зум, иначе в хроме скачками карта зумится
    const handleCenterView = useCallback((newCenter) => {
        setLocation({
            center: [newCenter.lng, newCenter.lat],
            zoom: viewLocation.zoom,
        });
        /* setCenter(prevCenter => {
          // карта не реагирует на более мелкие изменения центра
          if (prevCenter.lat === newCenter.lat && prevCenter.lng === newCenter.lng) {
            return {
              lat: newCenter.lat + 0.00001,
              lng: newCenter.lng + 0.00001,
            };
          }
          return newCenter;
        });*/
    }, [viewLocation.zoom]);
    useEffect(() => {
        setViewLocation(location);
    }, [location]);
    const handleMarkerClick = useCallback((key, otherProps) => {
        // console.log('deltasForZoom[(location.zoom)]=',deltasForZoom[Math.round(location.zoom)]);
        // console.log('otherProps.lat + 0.1 * deltasForZoom[(location.zoom)]=',otherProps.lat + 0.1 * deltasForZoom[Math.round(location.zoom)]);
        if (selected === key) {
            setSelected(undefined);
            // setValue('partnerOfficeId', undefined);
            // setValue('office', undefined);
            // setValue('partnerOfficeIdCentered', undefined);
            // stateAppActions.setAppStateOrder(2);
        }
        else {
            setSelected(key);
            // setValue('partnerOfficeId', key);
            // setValue('office', offices.find(({ id }) => id === selected));
            // setValue('partnerOfficeIdCentered', key);
            // stateAppActions.setAppStateOrder(3);
            if ((otherProps === null || otherProps === void 0 ? void 0 : otherProps.lat) && (otherProps === null || otherProps === void 0 ? void 0 : otherProps.lng)) {
                handleCenterView({
                    // lat: otherProps.lat + 0.1 * deltasForZoom[(tempZoom - MIN_ZOOM + 1)],
                    // lat: otherProps.lat + deltasForZoom[Math.round(location.zoom)],
                    // lat: otherProps.lat + location.zoom/142.85,
                    // lat: otherProps.lat + location.zoom/142.85,
                    lat: otherProps.lat + Number(officeStore.koef) || deltasForZoom[Math.round(viewLocation.zoom)],
                    // lat: otherProps.lat,
                    lng: otherProps.lng,
                });
            }
        }
    }, [selected, handleCenterView, viewLocation.zoom, deltasForZoom /* , setValue, offices */]);
    const markers = useMemo(() => visibleOffices.reduce((pins, office) => {
        if (office.lat !== undefined && office.lng !== undefined) {
            pins.push(_jsx(Marker, { lat: office.lat, lng: office.lng, office: office, isMobile: isMobile, selected: office.id === selected, handleSelected: () => handleMarkerClick(office.id, { lat: office.lat, lng: office.lng }) }, office.id));
        }
        return pins;
    }, []), [visibleOffices, selected, handleMarkerClick, isMobile]);
    const selectedOffice = useMemo(() => visibleOffices.find(office => office.id === selected), [selected, visibleOffices]);
    const handlePanToCurrentLocation = useCallback(e => {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                handleCenter(pos);
            });
        }
    }, [handleCenter]);
    /* useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
  
            handleCenter(pos);
          }
        );
      }
    }, [handleCenter]); */
    useEffect(() => {
        setSelected(partnerOfficeIdCentered);
        const partnerOffice = offices === null || offices === void 0 ? void 0 : offices.find(({ id }) => id === partnerOfficeIdCentered);
        if (partnerOffice && partnerOffice.lat && partnerOffice.lng) {
            handleCenter({
                // lat: partnerOffice.lat + 0.1 * deltasForZoom[(tempZoom - MIN_ZOOM + 1)], 
                // lat: partnerOffice.lat + 0.1 * deltasForZoom[Math.round(location.zoom)], 
                lat: partnerOffice.lat + Number(officeStore.koef) || deltasForZoom[Math.round(viewLocation.zoom)],
                lng: partnerOffice.lng,
            });
        }
    }, [partnerOfficeIdCentered]);
    useEffect(() => {
        if (!selected) {
            const localPartnerOfficeId = params.get('partnerOfficeId');
            if (localPartnerOfficeId) {
                const officeFromUrl = visibleOffices.find(v => v.id === localPartnerOfficeId);
                if (officeFromUrl) {
                    handleMarkerClick(officeFromUrl.id, { lat: officeFromUrl.lat, lng: officeFromUrl.lng });
                }
            }
        }
    }, [visibleOffices, selected]);
    // блок для debug-zoom режима
    useEffect(() => {
        // officeActions.updateZoom(location.zoom);
        officeActions.updateLocation(viewLocation);
    }, [viewLocation]);
    useEffect(() => {
        if (selected && params.get('debug-zoom')) {
            officeActions.updateSelectedOffice(offices.find(v => v.id === selected));
        }
        ;
    }, [selected]);
    useEffect(() => {
        // officeActions.updateZoom(location.zoom);
        officeActions.updateKoef(deltasForZoom[Math.round(viewLocation.zoom)]);
    }, [viewLocation.zoom]);
    // конец блока для debug-zoom режима
    // const hostname = (window.location.hostname ?? '').trim();
    // console.log('hostname=',hostname);
    // console.log('markers=',markers);
    // TODO - тут надо поискать решение и ставить центр карты на геопозицию пользователя
    // если данных нет, или доступ запрещён - ставь центр Москвы
    return (
    // Important! Always set the container height explicitly
    _jsxs(_Fragment, { children: [_jsx("div", { className: 'map_box', onClick: (e) => {
                    var _a;
                    // Lifehack - нужно было перехватить клик по чисттой карте, чтоб закрыть баллун
                    // e?.target?.nodeName==='CANVAS' - пришлось такую проверку прикрутить. Вроде, пока работает как надо.
                    if (selected && ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.nodeName) === 'CANVAS')
                        handleMarkerClick();
                }, children: _jsx("div", { className: 'App', children: _jsx(YMapComponentsProvider, { apiKey: process.env.YANDEX_API_KEY, lang: 'en_EN', children: _jsxs(YMap, { 
                            // ref={ (ymap: YMaps.YMap) => setYmap(ymap) }
                            location: location, mode: 'vector', 
                            // TODO - Зум не влияет на кнопки справа на карте. Надо додумать, как их блокировать при Зуме мышкой, а не при клике
                            zoomRange: { min: MIN_ZOOM, max: MAX_ZOOM }, children: [_jsx(YMapDefaultSchemeLayer, {}), _jsx(YMapDefaultFeaturesLayer, {}), _jsx(YMapListener, { onUpdate: onUpdate }), _jsx(YMapHint, { hint: getHint, children: _jsx(MyHint, {}) }), markers] }, 'map'
                        // ref={ (ymap: YMaps.YMap) => setYmap(ymap) }
                        ) }) }) }), _jsxs("div", { className: 'map_ui', children: [_jsx("button", { type: 'button', className: 'map_ui_button map_ui_plus', onClick: zoomIn, disabled: viewLocation.zoom >= MAX_ZOOM, children: _jsx(PlusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button map_ui_minus', onClick: zoomOut, disabled: viewLocation.zoom <= MIN_ZOOM, children: _jsx(MinusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button', onClick: handlePanToCurrentLocation, children: _jsx(PositionArrowIcon, {}) })] }), isMobile && selectedOffice && (_jsx(MobileModalForm, { noHeader: true, showCloseButton: true, label: selectedOffice.name || '', onClose: () => {
                    setSelected(undefined);
                }, children: _jsx(OfficeCard, { office: selectedOffice, onClose: () => {
                        setSelected(undefined);
                    } }) }))] }));
};
export default inject('officeStore')(observer(MapV2));
