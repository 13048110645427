import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useForm, FormProvider, } from 'react-hook-form';
import LeftSidePanel from './LeftSidePanel';
import MapV2 from '../map/Map';
import MenuPanel from '../menu/MenuPanel';
import RightSidePanel from './RightSidePanel';
const PrimaryPanel = () => {
    var _a;
    const params = new URLSearchParams(window.location.search);
    const getDebugParam = () => {
        if (params.get('user') === 'gk') {
            return {
                firstName: 'Григорий',
                lastName: 'Кузнецов',
                phoneNumber: '+79267765897',
                // localTime: '12:00',
                // time: '1677576600',
                // appointmentJournalId: '6e949b2e-af7d-4618-96c3-24ff74db866f',
                // date: new Date('2023-03-01'),
                // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzkyNjc3NjU4OTciLCJleHAiOjE3MzkzMDYwMDZ9.zbpxKwFUD_ydADB0YZc2nbv5Nuh4jcpUH2GPYo08hwjGov54beYDawXyN9jPRHTkrfz2vwnWCKYQl5Qt0XRofw',
            };
        }
        if (params.get('user') === 'ak') {
            return {
                firstName: 'Алексей',
                lastName: 'Киликеев',
                phoneNumber: '+79163005252',
                // token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzkxNjMwMDUyNTMiLCJleHAiOjE3Mzg4NTU5NDB9.SU-mykuyJ_A9uZ37wPqMwjutkeXPKDawOA6lr_TVxcbp38Mrjx6MQP2IjYK5w2BnGkuLNLVFJplBT_pIr8G3ZA',
            };
        }
        if (params.get('user') === 'df') {
            return {
                firstName: 'Дмитрий',
                lastName: 'Фёдоров',
                phoneNumber: '+79636967530',
                // token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzk2MzY5Njc1MzAiLCJleHAiOjE3Mzk1MjI3Njh9.S0MJ-6tWnsbT71TUm0iEw4dqLxTAc0SopC85HDoxqDPTfFTEMUFuiXdwVqq2bMmLR9mjALUHoOk07qRuhinleQ',
            };
        }
        return {};
    };
    /* const getTireOperation = () => {
      if (globalThis?.tireOperation) {
        switch (globalThis?.tireOperation) {
          case 1:
            return 361;
          case 2:
            return 244;
          case 3:
            return 487;
          default:
            return null
        }
      };
      return null;
    }*/
    const methods = useForm({
        defaultValues: Object.assign(Object.assign({}, getDebugParam()), { 
            // tireOperationRefValueId: getTireOperation() ?? parseInt(params.get('tireOperationRefValueId'), 10) ?? null,
            tireOperationRefValueId: (_a = parseInt(params.get('tireOperationRefValueId'), 10)) !== null && _a !== void 0 ? _a : null, 
            // partnerOfficeId: params.get('partnerOfficeId') ?? null,
            // partnerOfficeIdCentered: params.get('partnerOfficeIdCentered') ?? null,
            hasTireStorage: false, tireCount: 4, vehicleTypeRefValueId: 43, diameterWheelRefValueId: 32, tireRunflatOptionValue: 'no', whereStoreOptionValue: 'no', 
            // tireOperationRefValueId: 244,
            tireCountStorage: 4, storedObjectRefValueId: 379, storageDurationInMonths: 6, 
            // для управления тенью зададим сразу scrollY =0
            scrollY: 0, minimized: false, utmCampaign: params.get('utm_campaign'), utmContent: params.get('utm_content'), utmMedium: params.get('utm_medium'), utmSource: params.get('utm_source'), utmTerm: params.get('utm_term') }),
        mode: 'onChange',
    });
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("div", { className: 'main_container_inner', children: [_jsx(MenuPanel, {}), _jsx(MapV2, {}), _jsx(LeftSidePanel, {}), _jsx(RightSidePanel, {})] }) })));
};
export default inject('stateAppStore')(observer(PrimaryPanel));
