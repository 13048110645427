var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { useMediaQuery } from '@react-hooks-library/core';
import { referenceActions } from './store/referenceStore';
import { officeActions } from '../map/store/officeStore';
// import { OfficeRequestInterface } from '../map/api/offices';
import { Header, Footer, PriceFrom } from '../core';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
import { exactPrice } from './consts';
const TireOperationSelectPanel = (props) => {
    var _a, _b, _c;
    const { referenceStore, officeStore } = props;
    const ref56 = (_a = referenceStore === null || referenceStore === void 0 ? void 0 : referenceStore.ref56) !== null && _a !== void 0 ? _a : [];
    const { officesRequest, officesRequestByParams } = officeStore;
    const { scroller, positions } = useScrollWithShadow(ref56.length);
    const { register, watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [showRequired, setShowRequired] = useState(false);
    const hasTireStorage = (_b = values.hasTireStorage) !== null && _b !== void 0 ? _b : false;
    const tireOperationRefValueId = (_c = values.tireOperationRefValueId) !== null && _c !== void 0 ? _c : null;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const loadOffices = () => __awaiter(void 0, void 0, void 0, function* () {
        const isEqualTireOperationArray = !officesRequest || isEqual(officesRequest.tireOperationRefValueId, tireOperationRefValueId ? [tireOperationRefValueId] : []);
        if ((!officesRequest)
            || (officesRequest.hasTireStorage !== hasTireStorage)
            || (officesRequest.showPhotoList !== false)
            || (!isEqualTireOperationArray)
            || (!!officesRequestByParams) // обновим список, если до этого офисы выбрали по officesRequestByParams
        ) {
            yield officeActions.getOffices({
                tireOperationRefValueId: tireOperationRefValueId ? [tireOperationRefValueId] : [],
                hasTireStorage,
                showPhotoList: true,
            });
        }
    });
    /* const getTireOperation = () => {
      if (globalThis?.tireOperation) {
        switch (globalThis?.tireOperation) {
          case 1:
            return 361;
          case 2:
            return 244;
          case 3:
            return 487;
          default:
            return null
        }
      };
      return null;
    }; */
    useEffect(() => {
        loadOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTireStorage, tireOperationRefValueId]);
    /* useEffect(() => {
      setValue('tireOperationRefValueId', getTireOperation());
    }, [globalThis?.tireOperation]);*/
    useEffect(() => {
        var _a;
        const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield referenceActions.getRef56();
        });
        loadData();
        // Блок получение данных с везём колёса:
        if ((sessionStorage.getItem('tireOperationRefValueId')) && (!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId))) {
            setValue('tireOperationRefValueId', parseInt((_a = sessionStorage.getItem('tireOperationRefValueId')) !== null && _a !== void 0 ? _a : '361', 10));
        }
        // Конец блок получение данных с везём колёса:
    }, []);
    const setServiceId = useCallback(() => {
        if (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) {
            setValue('serviceId', 68);
        }
        else if (values === null || values === void 0 ? void 0 : values.hasTireStorage) {
            setValue('serviceId', 69);
        }
        else {
            setValue('serviceId', null);
        }
    }, [values === null || values === void 0 ? void 0 : values.tireOperationRefValueId, values === null || values === void 0 ? void 0 : values.hasTireStorage, setValue]);
    useEffect(() => {
        setShowRequired(false);
        setServiceId();
    }, [tireOperationRefValueId, setServiceId]);
    useEffect(() => {
        setServiceId();
    }, [hasTireStorage, setServiceId]);
    const validation = () => {
        if ((!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)) && (!(values === null || values === void 0 ? void 0 : values.hasTireStorage))) {
            setShowRequired(true);
            return false;
        }
        return true;
    };
    const handleChange = (v) => {
        setValue('tireOperationRefValueId', (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === v.id ? null : v.id);
        setValue('tireOperationRefValueName', (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === v.id ? null : v.name);
        setValue('isExactPrice', exactPrice.includes(v.id));
        if (v.id === 487) {
            setValue('tireRunflatOptionValue', 'yes');
        }
        else {
            setValue('tireRunflatOptionValue', 'no');
        }
    };
    const params = new URLSearchParams(window.location.search);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 0, header: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0438', noNavigate: !isMobile || !!values.minimized, noReturn: true, gradient: true, onCloseClick: () => { setValue('minimized', !values.minimized); }, shadow: positions.top }), !values.minimized && (_jsxs("div", { className: 'padding_main_layer scroll_wrapper', ref: scroller, children: [_jsxs(Row, { children: [_jsx("div", { className: 'label', children: "\u0423\u0441\u043B\u0443\u0433\u0438 \u043F\u043E \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044E \u0448\u0438\u043D" }), _jsx(Form, { className: 'checkbox', children: _jsx(Form.Check, Object.assign({ type: 'checkbox', id: 'default-storage', label: '\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D' }, register('hasTireStorage'))) })] }), _jsx(Row, { className: 'delimiterMain', children: _jsx("div", {}) }), _jsxs(Row, { className: 'tire_operation_list', children: [_jsx("div", { className: 'label pt-1', children: "\u041A\u043E\u043C\u043F\u043B\u0435\u043A\u0441\u043D\u044B\u0435 \u0443\u0441\u043B\u0443\u0433\u0438 \u0448\u0438\u043D\u043E\u043C\u043E\u043D\u0442\u0430\u0436\u0430" }), _jsx(Form, { children: ref56.filter(v => v.sort < 30).map((v) => {
                                    return (_jsxs("div", { children: [_jsx(Form.Check
                                            // { ...register('tireOperationRefValueId') }
                                            , { 
                                                // { ...register('tireOperationRefValueId') }
                                                id: `radio-selector${v.id}`, type: 'radio', label: v.name, value: v.id, onClick: () => { handleChange(v); }, onChange: () => { handleChange(v); }, checked: (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === v.id }), _jsx("div", { className: 'added_content', children: v === null || v === void 0 ? void 0 : v.string1 })] }, v.id));
                                }) })] }), _jsxs(Row, { className: 'tire_operation_list', children: [_jsx("div", { className: 'label pt-1', children: "\u0414\u043E\u043F.\u0443\u0441\u043B\u0443\u0433\u0438" }), _jsx(Form, { children: ref56.filter(v => v.sort >= 30).map((v) => {
                                    return (_jsx(Form.Check
                                    // { ...register('tireOperationRefValueId') }
                                    , { 
                                        // { ...register('tireOperationRefValueId') }
                                        id: `radio-selector${v.id}`, type: 'radio', label: v.name, value: v.id, onClick: () => { handleChange(v); }, onChange: () => { handleChange(v); }, checked: (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === v.id }, v.id));
                                }) })] }), showRequired
                        && (_jsx(Row, { className: 'pt-2', children: _jsx(Col, { className: 'font-required_red_16', children: "\u0427\u0442\u043E\u0431\u044B \u043F\u0440\u043E\u0439\u0442\u0438 \u0434\u0430\u043B\u044C\u0448\u0435, \u0441\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0443" }) })), (!!params.get('debug'))
                        && (_jsxs(_Fragment, { children: [officeStore.offices && (officeStore.offices.length > 0)
                                    && (_jsx(Row, { children: _jsx(Col, { children: _jsx(PriceFrom, { office: officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices[0] }) }) })), _jsx(Row, { children: _jsx(Col, { children: officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices.map(v => {
                                            var _a, _b, _c, _d;
                                            return (_jsx("li", { children: `${v.name}- Бонус(%) 68-${(_b = (_a = v === null || v === void 0 ? void 0 : v.bonusReceivingPercentByService) === null || _a === void 0 ? void 0 : _a.stationTireFitting) !== null && _b !== void 0 ? _b : ' Нет'}  69-${(_d = (_c = v === null || v === void 0 ? void 0 : v.bonusReceivingPercentByService) === null || _c === void 0 ? void 0 : _c.stationTireStorage) !== null && _d !== void 0 ? _d : ' Нет'}` }, v.id));
                                        }) }) })] }))] })), _jsx(Footer, { label: !values.minimized ? 'Далее' : 'Выбрать услуги', nextNumber: !values.minimized ? 1 : null, validation: validation, onClick: () => { setValue('minimized', false); }, shadow: positions.bottom })] }));
};
export default inject('referenceStore', 'officeStore')(observer(TireOperationSelectPanel));
